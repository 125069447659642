import { IAssets } from "../AdminHome";

const ClassifiedAssets = ({ assetsData }: { assetsData: IAssets }) => {
	return (
		<div className="bg-primary text-primary">
			<div className="relative isolate px-6 pt-4 lg:px-8">
				<div className="mx-auto max-w-2xl">
					<div className="text-center">
						<h1 className="text-2xl font-bold tracking-tight sm:text-4xl">{`${assetsData.isBroker ? "Broker" : "AOR"} Resource Materials`}</h1>
						<p className="mt-6">
							Please click {assetsData.image ? "the image" : "the button"} below to access your resource materials. You will be prompted to enter the following password: <span className="text-header font-bold">{assetsData.password}</span>
						</p>
						<div className="mt-10 flex items-center justify-center gap-x-6">
							{assetsData.image ? (
								<a href={assetsData.url} rel="noreferrer" target="_blank">
									<img src={assetsData.image} alt="Resource Materials" />
								</a>
							) : (
								<a href={assetsData.url} className="action-button bg-header hover:bg-opacity-80 cursor-pointer" rel="noreferrer" target="_blank">
									Click here to access your resource materials
								</a>
							)}
						</div>
						{assetsData.infoUrl &&
							<div className="mt-10 flex items-center justify-center gap-x-6">
								<p>
									To access the {assetsData.isBroker ? "broker" : "association"} support page, <a href={assetsData.infoUrl} target="_blank" rel="noreferrer" className="text-tertiary">please click here</a>.
								</p>
							</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClassifiedAssets;
