import { useSelector } from "react-redux";
import { userProfile } from "../user/selectors";

export const useCheckIsBroker = () => {
    const profile = useSelector(userProfile);
    if (["CB", "DB", "DN"].includes(profile?.userclass)) {
        return true;
    }
    return false;
}

export const useCheckIsGsmls = () => {
    const profile = useSelector(userProfile);
    if (["SWL", "NAB"].includes(profile.originatingSystemID)) {
        return true;
    }
    return false;
}

export const useGetAdminConfig = () => {
    const profile = useSelector(userProfile);
    const isBroker = useCheckIsBroker();
    const isGsmls = useCheckIsGsmls();

    if (isBroker) {
        const officeId = profile?.office?.id;
        const mainOfficeCode = profile?.office?.mainOfficeCode;
        return {
            profile,
            isBroker,
            isGsmls,
            sourceId: officeId,
            resourceMaterialsData: {
                url: process.env.REACT_APP_CONFIDENTIAL_DOCS_BROKER_URL,
                password: process.env.REACT_APP_CONFIDENTIAL_DOCS_BROKER_PASSWORD,
                image: process.env.REACT_APP_CONFIDENTIAL_DOCS_BROKER_IMAGE,
                infoUrl: process.env.REACT_APP_CONFIDENTIAL_DOCS_BROKER_INFO_URL,
                isBroker: isBroker
            },
            criteria: {
                articles: officeId ? [{ field: "sourceId", op: "Equal", values: [officeId] }] : null,
                article: officeId ? [{ field: "mainOfficeCode", op: "Equal", values: [mainOfficeCode] }] : null,
                messages: mainOfficeCode ? [{ field: "source", op: "Equal", values: ["AOR"] }, { field: "mainOfficeCode", op: "Contains", values: [mainOfficeCode] }] : null,
                message: mainOfficeCode ? [{ field: "mainOfficeCode", op: "Equal", values: [mainOfficeCode] }] : null
            },
            pageSize: 10
        }
    } else {
        const aorId = profile?.aor?.id;
        const aorShortName = profile?.aor?.shortName;
        return {
            profile,
            isBroker,
            isGsmls,
            sourceId: aorId,
            resourceMaterialsData: {
                url: process.env.REACT_APP_CONFIDENTIAL_DOCS_AOR_URL,
                password: process.env.REACT_APP_CONFIDENTIAL_DOCS_AOR_PASSWORD,
                image: process.env.REACT_APP_CONFIDENTIAL_DOCS_AOR_IMAGE,
                infoUrl: process.env.REACT_APP_CONFIDENTIAL_DOCS_AOR_INFO_URL,
                isBroker: isBroker
            },
            criteria: {
                articles: aorId ? [{ field: "sourceId", op: "Equal", values: [aorId] }] : null,
                article: aorId ? [{ field: "aorShortName", op: "Equal", values: [aorShortName] }] : null,
                messages: aorShortName ? [{ field: "source", op: "Equal", values: ["AOR"] }, { field: "AorShortName", op: "Contains", values: [aorShortName] }] : null,
                message: aorShortName ? [{ field: "AorShortName", op: "Equal", values: [aorShortName] }] : null
            },
            pageSize: 10
        }
    }
}